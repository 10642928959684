.kbn-page-card{
    position: relative;
    width: 100%;
    background: var(--background_tint);
    border-radius: .8em;
    border: .08em solid var(--color_rgba);
    padding: 1em;
    min-height: 9em;
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1em;
    overflow: hidden;
    transition: .3s ease;
    &:hover{
        box-shadow: 0 1px 1px rgba($color: #000000, $alpha: .08);
    }
    &__avatar{
        position: relative;
        width: 3.8em;
        height: 3.8em;
        border-radius: 50%;
        overflow: hidden;
        @extend %flex-center;
        background: var(--background);
        img{
            @extend %image-center;
        }
        i{
            font-size: 1.4em;
        }
        &.is--square{
            border-radius: .2em;
            width: 4.8em;
            height: 4.8em;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .2em;
        strong{
            font-size: 1.2em;
        }
    }
    &__actions{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        gap: .5em;
        flex-wrap: wrap;
    }
    &__action{
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 0.2em;
        padding: .6em .8em;
        background: var(--primary_rgba);
        border-radius: .4em;
        transition: .3s ease;
        &:hover{
            background: var(--primary);
            color: #fff;
        }
        &.is--danger{
            background: rgba($color: $danger, $alpha: .2);
            &:hover{
                background: $danger;
                color: #fff;
            }
        }
        &.is--disable{
            background: var(--background_tint);
            pointer-events: none;
            opacity: .8;
        }
    }
    .kbn-chip{
        position: absolute;
        bottom: 0.5em;
        right: 0.5em;
    }
    .kbn-tag{
        position: relative;
        padding: .2em .4em;
        background: var(--background);
        border-radius: .2em;
        font-size: .9em;
        // display: flex;
    }
}
.kbn-chip{
    position: relative;
    padding: .4em .8em;
    background: var(--background_tint);
    border-radius: 1em;
    // display: flex;
    &.is--success{
        background: rgba($color: $success, $alpha: .2);
    }
    &.is--danger{
        background: rgba($color: $danger, $alpha: .2);
    }
    &.is--warning{
        background: rgba($color: $warning, $alpha: .2);
    }
}
.kbn-tag{
    position: relative;
    padding: .2em .4em;
    background: var(--background_tint);
    border-radius: .2em;
    font-size: .9em;
    // display: flex;
}