.kbn-loader{
    position: fixed;
    width: 100%;
    height: 100svh;
    background: var(--background);
    top: 0;
    left: 0;
    z-index: 999;
    @extend %flex-center;
    font-size: 2em;
}
.kbn-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    height: 4em;
    background: var(--background);
    border-bottom: .08em solid var(--color_rgba);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5em 2em;
}
.kbn-header-logo{
    svg{
        height: 1.8em;
        width: auto;
        opacity: .5;
        &:hover{
            opacity: 1;
            color: var(--primary);
        }
    }
}
.kbn-header-account{
    position: relative;
    display: flex;
    align-items: center;
    gap: .5em;
}
.kbn-header-account-btn{
    position: relative;
    width: 2.6em;
    height: 2.6em;
    @extend %flex-center;
    background: var(--background_tint);
    border-radius: .8em;
    transition: .3s ease;
    &:hover{
        background: var(--primary_rgba);
        border-radius: 50%;
        .kbn-header-account-tooltip{
            display: block;
        }
    }
    &.active{
        background: var(--primary);
        color: var(--button_text_color);
    }
    span.notif{
        position: absolute;
        top: 0;
        right: 0;
        width: .8em;
        height: .8em;
        background: $danger;
        border-radius: 50%;
        display: block;
        white-space: nowrap;
    }
}
.kbn-header-account-tooltip{
    position: absolute;
    bottom: -50%;
    padding: .2em .5em;
    font-size: .9em;
    background: var(--color);
    color: var(--background);
    border-radius: .3em;
    display: none;
    white-space: nowrap;
}
.kbn-header-account-structure{
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 0.2em;
    padding: 0.5em .8em;
    min-height: 2.5em;
    transition: 0.3s ease;
    border-radius: .8em;
    font-size: .9em;
    color: var(--color);
    border: .1em solid var(--color_rgba);
    font-weight: bold;
    background: var(--background_tint);
}
.kbn-header-account-user{
    position: relative;

}
.kbn-header-account-user-btn{
    position: relative;
    display: flex;
    align-items: center;
    gap: .8em;
    font-size: .9em;
    font-weight: bold;
    &__avatar{
        position: relative;
        width: 2.6em;
        height: 2.6em;
        @extend %flex-center;
        background: var(--background_tint);
        border-radius: .8em;
        transition: .3s ease;
        overflow: hidden;
        img{
            @extend %image-center;
        }
    }
}
.kbn-header-account-user-menu{
    position: fixed;
    padding: .5em;
    right: 2em;
    background: var(--background);
    border-radius: .3em;
    border: .1em solid var(--color_rgba);
    display: flex;
    flex-direction: column;
    row-gap: .5em;
    z-index: 8;
    transform: translateY(.5em);
    &.is--inactive{
        display: none;
    }
    &__item{
        a, button{
            position: relative;
            display: flex;
            align-items: center;
            gap: .3em;
            font-weight: bold;
            padding: .4em;
            min-width: 13em;
            border-radius: .4em;
            &:hover{
                background: var(--primary_rgba);
            }
            &.active{
                background: var(--primary);
                color: var(--button_text_color);
            }
            &.danger{
                background: rgba($color: $danger, $alpha: .2);
                &:hover{
                    background: rgba($color: $danger, $alpha: 1);
                    color: #fff;
                }
            }
        }
    }
}
.kbn-navigation{
    position: fixed;
    width: 17em;
    height: 100svh;
    background: var(--background);
    border-right: .08em solid var(--color_rgba);
    top: 0;
    left: 0;
    z-index: 8;
    &.is--min,
    &.is--reduce{
        width: 4em;
        .kbn-navigation__container{
            padding: 2em .5em;
            padding-top: 6em;
        }
        
        .kbn-navigation-item{
            a{
                span{
                    position: absolute;
                    top: 50%; 
                    left: 110%;
                    transform: translateY(-50%);
                    padding: .2em .5em;
                    font-size: .9em;
                    background: var(--color);
                    color: var(--background);
                    border-radius: .3em;
                    display: none;
                    text-align: center;
                    white-space: nowrap;
                    z-index: 5;
                }
                &:hover{
                    span{
                        display: flex;
                    }
                }
            }
        }
    }
    &.is--unreduce{
        width: 100%;
        background: rgba($color: #000, $alpha: .2);
        border: none;
        .kbn-navigation__container{
            width: 17em;
            background: var(--background);
            border-right: .08em solid var(--color_rgba);
        }
    }
    &__container{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 2em 1em;
        padding-top: 6em;
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .5em;
    }
}
.kbn-navigation-btn{
    position: absolute;
    top: 4em;
    right: 0;
    transform: translateX(50%);
    height: 2.4em;
    width: 2.4em;
    border-radius: 50%;
    background: var(--background);
    border: .1em solid var(--color_rgba);
    @extend %flex-center;
    font-size: 1em;
    z-index: 2;
}

.kbn-navigation-item{
    position: relative;
    width: 100%;
    i{
        font-size: 1.2em;
    }
    a{
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 0.2em;
        padding: 0.5em .8em;
        min-height: 2.8em;
        transition: 0.3s ease;
        border-radius: .8em;
        font-size: .9em;
        color: var(--color);
        &:hover{
            background: var(--color_rgba);
        }
        &.active{
            background: var(--primary);
            color: #fff;
        }
    }
}
.kbn-main{
    position: relative;
    width: calc(100% - 17em);
    min-height: 100svh;
    margin-left: auto;
    padding: 2em 1.5em;
    padding-top: 5em;
    &.is--min,
    &.is--reduce,
    &.is--unreduce{
        width: calc(100% - 4em);
    }
}

.kbn-submenu{
    position: relative;
    width: 100%;
    height: 89.3svh;
    overflow-y: auto;
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    background: var(--background_tint);
    border-radius: .8em;
    &__title{
        font-size: 1.2em;
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .4em;
    }
    &__item{
        position: relative;
        width: 100%;
        i{
            font-size: 1.2em;
        }
        a{
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 0.2em;
            padding: 0.5em .8em;
            min-height: 2.8em;
            transition: 0.3s ease;
            border-radius: .4em;
            font-size: .9em;
            color: var(--color);
            &:hover{
                background: var(--color_rgba);
            }
            &.active{
                background: var(--primary);
                color: #fff;
            }
        }
    }
}
