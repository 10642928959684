.kbn-alert{
    position: relative;
    width: 100%;
    // max-width: 480px;
    padding: 1em .8em;
    display: flex;
    align-items: center;
    column-gap: 1em;
    border: .03em solid;
    border-radius: .4em;
    min-height: 3em;
    &.is--close{
      display: none;
    }
    &__icon{
      position: relative;
      width: 1em;
      i{
        font-size: 1.2em;
      }
    }
    &__close{
      position: relative;
      width: 1em;
      background: none;
      i{
        font-size: 1em;
      }
    }
    &__container{
      position: relative;
      width: calc(100% - 2.4em);
      display: flex;
      flex-direction: column;
      row-gap: 0.2em;
      font-size: .9em;
    }
  
    &.st-success{
      background: rgba($color: $success, $alpha: 0.1);
      border-color: $success;
    }
    &.st-danger{
      background: rgba($color: $danger, $alpha: 0.2);
      border-color: $danger;
    }
    &.st-info{
      background: rgba($color: $info, $alpha: 0.1);
      border-color: $info;
    }
    &.st-warning{
      background: rgba($color: $warning, $alpha: 0.1);
      border-color: $warning;
    }
  }
  
.kbn-toast{
    position: fixed;
    bottom: 8svh;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    width: 100%;
    &.is--close{
        display: none;
    }
    &.is--open{
        @extend %flex-center;
    }
    .kbn-toast-contain{
        position: relative;
        // width: 100%;
        max-width: 25em;
        padding: 0.4em .8em;
        background: var(--color);
        color: var(--background);
        display: flex;
        align-items: center;
        column-gap: 0.5em;
        border-radius: .4em;
        &__icon{
            position: relative;
            width: 1em;
            i{
                font-size: 1em;
            }
            }
        &__container{
            position: relative;
            width: calc(100% - 1.2em);
            display: flex;
            flex-direction: column;
            row-gap: 0.2em;
            font-size: .9em;
        }

        &.st-success{
            i{
                color: $success;
            }

            #fill_color{
                fill: $success;
            }
            #stroke_color{
                stroke: $success;
            }
        }
        &.st-danger{
            i{
                color: $danger;
            }

            #fill_color{
                fill: $danger;
            }
            #stroke_color{
                stroke: $danger;
            }
        }
        &.st-info{
            i{
                color: $info;
            }
            #fill_color{
                fill: $info;
            }
            #stroke_color{
                stroke: $info;
            }
        }
        &.st-warning{
            i{
                color: $warning;
            }
            #fill_color{
                fill: $warning;
            }
            #stroke_color{
                stroke: $warning;
            }
        }
    }
}
