.kbn-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    z-index: 9999;
    background: rgba($color: $background_dark, $alpha: 0.8);
    display: none;
    &.is--open{
      display: block;
    }
    &__close-btn{
      position: absolute;
      top: 0.625em;
      right: 0.625em;
      width: 2.1875em;
      height: 2.1875em;
      z-index: 9;
      background: var(--background_tint);
      @extend %flex-center;
      border-radius: .2em;
      i{
        font-size: 1em;
      }
    }
  }
  .kbn-modal-container{
    position: relative;
    width: 100%;
    padding: 0.625em;
    min-height: 12.5em;
    margin: 2svh auto auto;
    max-height: 90svh;
    overflow-y: auto;
    background: var(--background);
    border-radius: .4em;
    border: .01em solid var(--color_rgba);
    &.is--xl{
      max-width: 80em;
    }
    &.is--md{
      max-width: 40em;
    }
    &.is--sm{
      max-width: 30em;
    }
  }
  .kbn-modal-form{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .9em;
    padding: 1em;
    // min-height: 35em;
    &__header{
      position: relative;
      width: 100%;
      text-align: var(--text-align);
      padding-right: 4em;
      &.type-2{
        text-align: center;
      }
      h2{
        font-size: 1.3em;
      }
    }
    &__container{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1em;
    }
    &__fields{
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: .5em;
    }
    &__field{
      position: relative;
      width: 100%;
      max-width: 37em;
    }
    &__action{
      position: relative;
      width: 100%;
      button{
        position: relative;
        width: 100%;
        @extend %flex-center;
      }
    }
  }


.kbn-modal-form-status{
    position: relative;
    width: 100%;
    min-height: 25em;
    padding: 1em;
    @extend %flex-center;
    &__container{
        position: relative;
        @extend %flex-center;
        text-align: center;
        flex-direction: column;
        row-gap: 1em;
    }
    &__icon{
        position: relative;
        font-size: 2em;
        width: 3em;
        height: 3em;
        @extend %flex-center;
        background: var(--background_tint);
        border-radius: .8em;
        &.is--danger{
            color: $danger;
            background: rgba($color: $danger, $alpha: 0.2);
        }
        &.is--success{
            color: $success;
            background: rgba($color: $success, $alpha: 0.2);
        }
    }
    &__text{
        @extend %flex-center;
        flex-direction: column;
        row-gap: .2em;
    }
    &__actions{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5em;
    }
    &__action{
        margin-top: .8em;
        display: flex;
        gap: .4em;
    }
}

.kbn-modal-form-list{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: .8em;
  &__head{
    position: relative;
    width: 100%;
    h3{
      font-size: 2em;
    }
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
  }
}
.kbn-modal-form-list-item{
  position: relative;
  width: 100%;
  background: var(--background_tint);
  border-radius: .8em;
  border: .08em solid var(--color_rgba);
  padding: 1em;
  box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: .5em;
  align-items: center;
  &__container{
    position: relative;
    display: flex;
    flex-direction: column;
    p{
      position: relative;
      // max-width: 25em;
    }
  }
  &__actions{
    position: relative;
    @extend %flex-center;
    gap: .2em
  }
  &__action{
    position: relative;
    padding: .4em;
    @extend %flex-center;
    background: var(--primary_rgba);
    color: var(--color);
    border-radius: .4em;
    // border: .08em solid var(--color_rgba);
    font-size: 1.2em;
    &.is--danger{
      background: rgba($color: $danger, $alpha: 0.1);
    }
    &.is--disable{
      background: none;
      pointer-events: none;
    }
  }
}


.kbn-modal-form-items-choice{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__search{
    position: relative;
    width: 100%;
    display: flex;
    gap: .5em;
    padding: .2em .4em;
    background: var(--background);
    align-items: center;
    i{
      font-size: 1.1em;
    }
    input{
      position: relative;
      width: calc(100% - 2.3em);
      min-height: 2.75em;
      border: none;
      background: none;
      outline: none;
      color: currentColor;
    }
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
  }
}

.kbn-modal-form-item{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: .5em;
  border-bottom: .02em solid var(--color_tint);
  &__container{
    position: relative;
    display: flex;
    align-items: center;
    column-gap: .5em;
  }
  &__avatar{
    position: relative;
    width: 3.5em;
    height: 3.5em;
    background-color: var(--background);
    border-radius: 50%;
    @extend %flex-center;
    i{
      font-size: 1.3em;
    }
    img{
      @extend %image-center
    }
  }
  &__info{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: .1em;
  }
}

.kbn-cpt{
  position: relative;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;

  &__header{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
    h1{
      font-size: 1.3em;
    }
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
  }
}
.kbn-cpt-box{
  position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
    background: var(--background_tint);
    padding: 1em;
    border-radius: .8em;
    &__header{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: .7em;
    }
    &__actions{
        display: flex;
        gap: .5em;
        flex-wrap: wrap;
    }
    &__action{
        display: flex;
        gap: .2em;
        opacity: .7;
        align-items: center;
        font-size: .8em;
        span{
            text-decoration: underline;
        }
        &:hover{
            opacity: 1;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        display: block;
        &.is--min{
            display: none;
        }
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .8em;
    }
    &__item{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .2em;
        span{
            font-size: .9em;
        }
    }
    &__card{
        width: 100%;
        position: relative;
        padding: .5em;
        border-radius: .4em;
        border: .1em solid var(--color_rgba);
        display: flex;
        flex-direction: column;
    }
}

.kbn-modal-files-status{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__groups{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
  }
}
.kbn-modal-files-status-group{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: .5em;
  h3{
    padding-bottom: .3em;
    border: .08em solid var(--color_rgba);
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2em;
  }
}

.kbn-modal-files-status-file{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: .3em;
  &__preview{
    position: relative;
    border-radius: .2em;
    width: 4.8em;
    height: 4.8em;
    overflow: hidden;
    @extend %flex-center;
    background: var(--background);
    img, video{
      @extend %image-center;
    }
    i{
      font-size: 1.4em;
    }
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .1em;
  }
}
.kbn-modal-files-status-file-progess{
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 1em;
  border-radius: 1em;
  background: var(--background_tint);
  &__container{
    position: relative;
    height: 100%;
    border-radius: 1em;
    &.is--progress{
      background: $info;
    }
    &.is--completed{
      background: $success;
    }
    &.is--error{
      background: $danger;
    }
  }
}