@font-face {
  font-family: 'kbn';
  src: url('../font/kbn.eot?96641514');
  src: url('../font/kbn.eot?96641514#iefix') format('embedded-opentype'),
       url('../font/kbn.woff2?96641514') format('woff2'),
       url('../font/kbn.woff?96641514') format('woff'),
       url('../font/kbn.ttf?96641514') format('truetype'),
       url('../font/kbn.svg?96641514#kbn') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'kbn';
    src: url('../font/kbn.svg?96641514#kbn') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "kbn";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-anchor:before { content: '\e800'; } /* '' */
.icon-arrow-down-small-big:before { content: '\e801'; } /* '' */
.icon-arrow-right-from-bracket:before { content: '\e802'; } /* '' */
.icon-arrow-narrow-up:before { content: '\e803'; } /* '' */
.icon-arrow-up-right-from-square:before { content: '\e804'; } /* '' */
.icon-arrow-up-big-small:before { content: '\e805'; } /* '' */
.icon-arrow-right-to-bracket:before { content: '\e806'; } /* '' */
.icon-arrow-down-to-square:before { content: '\e807'; } /* '' */
.icon-arrow-up-from-square:before { content: '\e808'; } /* '' */
.icon-at-sign:before { content: '\e809'; } /* '' */
.icon-alarm-clock-alt:before { content: '\e80a'; } /* '' */
.icon-album-collection:before { content: '\e80b'; } /* '' */
.icon-annotation:before { content: '\e80c'; } /* '' */
.icon-arrow-narrow-down:before { content: '\e80d'; } /* '' */
.icon-arrow-down-short-wide:before { content: '\e80e'; } /* '' */
.icon-arrow-narrow-right:before { content: '\e80f'; } /* '' */
.icon-arrow-up-wide-short:before { content: '\e810'; } /* '' */
.icon-arrow-narrow-left:before { content: '\e811'; } /* '' */
.icon-award:before { content: '\e812'; } /* '' */
.icon-briefcase-alt:before { content: '\e813'; } /* '' */
.icon-bank:before { content: '\e814'; } /* '' */
.icon-building:before { content: '\e815'; } /* '' */
.icon-backpack:before { content: '\e816'; } /* '' */
.icon-book:before { content: '\e817'; } /* '' */
.icon-building-shield:before { content: '\e818'; } /* '' */
.icon-basket-shopping:before { content: '\e819'; } /* '' */
.icon-bell-alt:before { content: '\e81a'; } /* '' */
.icon-bag-shopping:before { content: '\e81b'; } /* '' */
.icon-bell-slash:before { content: '\e81c'; } /* '' */
.icon-berong-trade:before { content: '\e81d'; } /* '' */
.icon-ban:before { content: '\e81e'; } /* '' */
.icon-badge-dollar:before { content: '\e81f'; } /* '' */
.icon-basket-shopping-alt:before { content: '\e820'; } /* '' */
.icon-badge-check:before { content: '\e821'; } /* '' */
.icon-bags-shopping:before { content: '\e822'; } /* '' */
.icon-box-archive:before { content: '\e823'; } /* '' */
.icon-briefcase-alt-1:before { content: '\e824'; } /* '' */
.icon-book-open:before { content: '\e825'; } /* '' */
.icon-bookmark:before { content: '\e826'; } /* '' */
.icon-book-user:before { content: '\e827'; } /* '' */
.icon-bullseye-arrow:before { content: '\e828'; } /* '' */
.icon-chart-line-up:before { content: '\e829'; } /* '' */
.icon-camera:before { content: '\e82a'; } /* '' */
.icon-cart-shopping:before { content: '\e82b'; } /* '' */
.icon-chart-pie-1:before { content: '\e82c'; } /* '' */
.icon-cart-plus:before { content: '\e82d'; } /* '' */
.icon-calendar-check:before { content: '\e82e'; } /* '' */
.icon-chart-area:before { content: '\e82f'; } /* '' */
.icon-circle-exclamation:before { content: '\e830'; } /* '' */
.icon-chart-user-square:before { content: '\e831'; } /* '' */
.icon-camera-slash:before { content: '\e832'; } /* '' */
.icon-city:before { content: '\e833'; } /* '' */
.icon-calendar-lines-pen:before { content: '\e834'; } /* '' */
.icon-cart-xmark:before { content: '\e835'; } /* '' */
.icon-circle-question:before { content: '\e836'; } /* '' */
.icon-credit-card:before { content: '\e837'; } /* '' */
.icon-certificate-check:before { content: '\e838'; } /* '' */
.icon-cart-shopping-fast:before { content: '\e839'; } /* '' */
.icon-chart-pie:before { content: '\e83a'; } /* '' */
.icon-calendar:before { content: '\e83b'; } /* '' */
.icon-castle:before { content: '\e83c'; } /* '' */
.icon-circle-exclamation-check:before { content: '\e83d'; } /* '' */
.icon-church:before { content: '\e83e'; } /* '' */
.icon-circle-information:before { content: '\e83f'; } /* '' */
.icon-circle-user:before { content: '\e840'; } /* '' */
.icon-calendar-alt:before { content: '\e841'; } /* '' */
.icon-credit-card-scan:before { content: '\e842'; } /* '' */
.icon-clipboard-list:before { content: '\e843'; } /* '' */
.icon-cart-check:before { content: '\e844'; } /* '' */
.icon-dots-horizontal:before { content: '\e845'; } /* '' */
.icon-download:before { content: '\e846'; } /* '' */
.icon-eye-slash-alt:before { content: '\e847'; } /* '' */
.icon-eye-alt:before { content: '\e848'; } /* '' */
.icon-drone:before { content: '\e849'; } /* '' */
.icon-dots-vertical:before { content: '\e84a'; } /* '' */
.icon-face-frown:before { content: '\e84b'; } /* '' */
.icon-face-smile:before { content: '\e84c'; } /* '' */
.icon-fingerprint:before { content: '\e84d'; } /* '' */
.icon-flag:before { content: '\e84e'; } /* '' */
.icon-folder:before { content: '\e84f'; } /* '' */
.icon-gear:before { content: '\e850'; } /* '' */
.icon-gift:before { content: '\e851'; } /* '' */
.icon-globe-alt:before { content: '\e852'; } /* '' */
.icon-globe-dollar:before { content: '\e853'; } /* '' */
.icon-globe-stand:before { content: '\e854'; } /* '' */
.icon-graduation-hat:before { content: '\e855'; } /* '' */
.icon-grid:before { content: '\e856'; } /* '' */
.icon-heart:before { content: '\e857'; } /* '' */
.icon-image-square:before { content: '\e858'; } /* '' */
.icon-laptop-medical:before { content: '\e859'; } /* '' */
.icon-hospital:before { content: '\e85a'; } /* '' */
.icon-handshake:before { content: '\e85b'; } /* '' */
.icon-hospitals:before { content: '\e85c'; } /* '' */
.icon-loader:before { content: '\e85d'; } /* '' */
.icon-image:before { content: '\e85e'; } /* '' */
.icon-kit-medical:before { content: '\e85f'; } /* '' */
.icon-list-ul-alt:before { content: '\e860'; } /* '' */
.icon-link-slash:before { content: '\e861'; } /* '' */
.icon-house-line:before { content: '\e862'; } /* '' */
.icon-link:before { content: '\e863'; } /* '' */
.icon-location-pin:before { content: '\e864'; } /* '' */
.icon-hospital-user:before { content: '\e865'; } /* '' */
.icon-lightbulb:before { content: '\e866'; } /* '' */
.icon-lock:before { content: '\e867'; } /* '' */
.icon-layer-group:before { content: '\e868'; } /* '' */
.icon-hourglass-end:before { content: '\e869'; } /* '' */
.icon-mail-open:before { content: '\e86a'; } /* '' */
.icon-mailbox:before { content: '\e86b'; } /* '' */
.icon-map-location-pin:before { content: '\e86c'; } /* '' */
.icon-marker:before { content: '\e86d'; } /* '' */
.icon-mask-face:before { content: '\e86e'; } /* '' */
.icon-memo-pencil:before { content: '\e86f'; } /* '' */
.icon-message-square-chat:before { content: '\e870'; } /* '' */
.icon-message-square:before { content: '\e871'; } /* '' */
.icon-microphone:before { content: '\e872'; } /* '' */
.icon-microphone-slash:before { content: '\e873'; } /* '' */
.icon-microscope:before { content: '\e874'; } /* '' */
.icon-minus:before { content: '\e875'; } /* '' */
.icon-mobile-signal-out:before { content: '\e876'; } /* '' */
.icon-mobile-signal:before { content: '\e877'; } /* '' */
.icon-money-withdrawal:before { content: '\e878'; } /* '' */
.icon-monitor-heart-rate:before { content: '\e879'; } /* '' */
.icon-moon-stars:before { content: '\e87a'; } /* '' */
.icon-moped:before { content: '\e87b'; } /* '' */
.icon-note-sticky:before { content: '\e87c'; } /* '' */
.icon-notes:before { content: '\e87d'; } /* '' */
.icon-paperclip-slash:before { content: '\e87e'; } /* '' */
.icon-paperclip:before { content: '\e87f'; } /* '' */
.icon-party-bell:before { content: '\e880'; } /* '' */
.icon-pen-circle:before { content: '\e881'; } /* '' */
.icon-percent:before { content: '\e882'; } /* '' */
.icon-pen-swirl:before { content: '\e883'; } /* '' */
.icon-phone:before { content: '\e884'; } /* '' */
.icon-prescription-bottle:before { content: '\e885'; } /* '' */
.icon-presentation-screen:before { content: '\e886'; } /* '' */
.icon-reciept:before { content: '\e887'; } /* '' */
.icon-school:before { content: '\e888'; } /* '' */
.icon-refresh-cw:before { content: '\e889'; } /* '' */
.icon-seal-exclamation:before { content: '\e88a'; } /* '' */
.icon-prescription-bottle-medical:before { content: '\e88b'; } /* '' */
.icon-plus:before { content: '\e88c'; } /* '' */
.icon-seal-question:before { content: '\e88d'; } /* '' */
.icon-search-alt:before { content: '\e88e'; } /* '' */
.icon-send:before { content: '\e88f'; } /* '' */
.icon-send-alt-2:before { content: '\e890'; } /* '' */
.icon-shield-dollar:before { content: '\e891'; } /* '' */
.icon-shield:before { content: '\e892'; } /* '' */
.icon-shop:before { content: '\e893'; } /* '' */
.icon-sliders:before { content: '\e894'; } /* '' */
.icon-staff-snake:before { content: '\e895'; } /* '' */
.icon-star-exclamation:before { content: '\e896'; } /* '' */
.icon-star:before { content: '\e897'; } /* '' */
.icon-suitcase-medical:before { content: '\e898'; } /* '' */
.icon-sun-alt:before { content: '\e899'; } /* '' */
.icon-swatchbook:before { content: '\e89a'; } /* '' */
.icon-skull-crossbones:before { content: '\e89b'; } /* '' */
.icon-square-h:before { content: '\e89c'; } /* '' */
.icon-tag:before { content: '\e89d'; } /* '' */
.icon-tags:before { content: '\e89e'; } /* '' */
.icon-thermometer:before { content: '\e89f'; } /* '' */
.icon-thumbtack:before { content: '\e8a0'; } /* '' */
.icon-triangle-exclamation:before { content: '\e8a1'; } /* '' */
.icon-trash:before { content: '\e8a2'; } /* '' */
.icon-trophy:before { content: '\e8a3'; } /* '' */
.icon-truck:before { content: '\e8a4'; } /* '' */
.icon-university:before { content: '\e8a5'; } /* '' */
.icon-unlock:before { content: '\e8a6'; } /* '' */
.icon-upload:before { content: '\e8a7'; } /* '' */
.icon-user:before { content: '\e8a8'; } /* '' */
.icon-user-heart:before { content: '\e8a9'; } /* '' */
.icon-user-pen:before { content: '\e8aa'; } /* '' */
.icon-user-check-alt-1:before { content: '\e8ab'; } /* '' */
.icon-user-question:before { content: '\e8ac'; } /* '' */
.icon-user-search:before { content: '\e8ad'; } /* '' */
.icon-user-shield:before { content: '\e8ae'; } /* '' */
.icon-user-xmark-alt-1:before { content: '\e8af'; } /* '' */
.icon-users:before { content: '\e8b0'; } /* '' */
.icon-video-slash:before { content: '\e8b1'; } /* '' */
.icon-video:before { content: '\e8b2'; } /* '' */
.icon-weight-scale:before { content: '\e8b3'; } /* '' */
.icon-wifi-exclamation:before { content: '\e8b4'; } /* '' */
.icon-wifi-slash:before { content: '\e8b5'; } /* '' */
.icon-wave-pulse:before { content: '\e8b6'; } /* '' */
.icon-wifi:before { content: '\e8b7'; } /* '' */
.icon-xmark:before { content: '\e8b8'; } /* '' */
.icon-virus-covid-19:before { content: '\e8b9'; } /* '' */
.icon-spin-2:before { content: '\e8ba'; } /* '' */
.icon-spin:before { content: '\e8ca'; } /* '' */
