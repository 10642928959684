$barracuda_family: "Barracuda", sans-serif;

$poppin_family: "Poppins", sans-serif;

:root {
  --font: #{$poppin_family};
}

[data-font='default'] {
  --font: #{$poppin_family};
}
