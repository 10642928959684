.kbn-button{
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 0.2em;
    padding: 0.25em .8em;
    min-height: 2.75em;
    transition: 0.3s ease;
    border-radius: .4em;
    font-size: .98em;
    cursor: pointer;
    span{
      font-weight: bold;
    }
    svg{
      width: 1.3em;
      height: 1.3em;
    }
    i{
      font-size: 1.1em;
      color: currentColor;
    }
    &.is--neutral{
      background: var(--background_tint);
      color: var(--color);
      border: .1em solid var(--color_rgba);
    }
    &.is--disable{
      pointer-events: none;
      background: var(--background_tint);
      color: var(--color);
      opacity: 0.7;
    }
    &.is--primary{
      background: var(--button);
      color: var(--button_text_color);
    }
    &.is--secondary{
      border: .02em solid var(--button);
      // color: var(--button);
      background: var(--primary_rgba);
    }
    &.is--danger{
      border: .02em solid $danger;
      color: var(--text-color);
      background: rgba($color: $danger, $alpha: 0.1);
    }
    &.is--success{
      border: .02em solid $success;
      color: var(--text-color);
      background: rgba($color: $success, $alpha: 0.1);
    }
    &.is--warning{
      border: .02em solid $warning;
      background: rgba($color: $warning, $alpha: 0.1);
    }
}

.kbn-page-creating-choice-btn{
  position: relative;
    display: flex;
    align-items: center;
    column-gap: 0.2em;
    padding: 0.25em .8em;
    transition: 0.3s ease;
    border-radius: .4em;
    font-size: .98em;
    cursor: pointer;
    background: var(--background_tint);
    color: var(--color);
    border: .1em solid var(--color_rgba);
    span{
      font-weight: bold;
    }
}


.kbn-link{
  position: relative;
  padding: 0 0.25em;
  font-size: .98em;
  color: var(--color);
  display: flex;
  align-items: center;
  column-gap: 0.425em;
  i{
    font-size: 1em;
    color: currentColor;
  }
  svg{
    width: 1.8em;
    height: 1.8em;
    #fill_color{
      fill: currentColor;
    }
    #stroke_color{
      stroke: currentColor;
    }
  }
  &:hover{
    text-decoration: underline;
    font-weight: bold;
  }
  &.is--neutral{
    color: var(--color_tint);
    &:hover{
      color: var(--color);
    }
  }
  &.is--disable{
    opacity: 0.7;
  }
  &.is--primary{
    &:hover{
      color: var(--button);
    }
  }
  &.is--danger{
    &:hover{
      color: $danger;
    }
  }
  &.is--success{
    &:hover{
      color: $success;
    }
  }
  &.is--warning{
    &:hover{
      color: $warning;
    }
  }
}