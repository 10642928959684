.kbn-page-arianne{
    position: relative;
    display: flex;
    column-gap: .3em;
    opacity: .7;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: .8em;
    .active{
        font-weight: bold;
    }
    a{
        &:hover{
            text-decoration: underline;
        }
    }
}
.kbn-page{
    position: relative;
    width: 100%;
    // max-width: 95em;
    margin: auto;
    &.is--grid{
        display: flex;
    }
    &__side{
        position: fixed;
        width: 15em;
        z-index: 2;
    }
    &__container{
        position: relative;
        width: calc(100% - 15em);
        padding: 0 1em;
        // height: 89.3svh;
        // overflow-y: auto;
        margin-left: auto;
    }
}
.kbn-page-title{
    position: relative;
    width: 100%;
    font-size: 1.3em;
    margin-bottom: 1.8em;
}
.kbn-page-account{
    position: relative;
    width: 100%;
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
    justify-content: space-between;

    &__container{
        position: relative;
        width: calc(100% - 18.5em);
        height: 77svh;
        overflow-y: auto;
    }

    &__grid{
        position: relative;
        width: 100%;
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
    }

}
.kbn-page-account-information{
    position: relative;
    max-width: 16em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    align-items: center;
    text-align: center;
    &__avatar{
        position: relative;
        width: 13em;
        height: 13em;
        overflow: hidden;
        @extend %flex-center;
        border-radius: 50%;
        i{
            font-size: 3em;
        }
        img{
            @extend %image-center;
        }
        background: var(--background_tint);
    }
    &__actions{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 16em;
        row-gap: .4em;
    }
    &__action{
        position: relative;
        width: 100%;
        button{
            width: 100%;
            @extend %flex-center;
        }
    }
    p{
        font-size: .8em;
        opacity: .9;
    }
}

.kbn-page-account-box{
    position: relative;
    width: 100%;
    max-width: 30.75em;
    background: var(--background);
    border-radius: .8em;
    border: .08em solid var(--color_rgba);
    padding: 1em;
    height: 23em;
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: .8em;
    &.is--full{
        width: 100%;
        max-width: 100%;
        height: auto;
        min-height: 23em;
    }
    h2{
        font-size: 1.2em;
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .4em;
        padding-bottom: .8em;
    }
    &__item{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: .3em;
        flex-wrap: wrap;
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .2em;
        min-height: 18em;
        padding-bottom: .8em;
    }
    &__loader{
        position: relative;
        width: 100%;
        height: 100%;
        @extend %flex-center;
        font-size: 2em;
    }
    &__empty{
        position: relative;
        width: 100%;
        height: 13.2em;
        @extend %flex-center;
        flex-direction: column;
        row-gap: .2em;
        font-weight: bold;
        text-align: center;
        i{
            opacity: .9;
            font-size: 1.4em;
        }
    }
    &__structure{
        position: relative;
        width: 100%;
        height: 21em;
        @extend %flex-center;
        flex-direction: column;
        row-gap: .2em;
        font-weight: bold;
        i{
            opacity: .9;
            font-size: 3em;
        }
    }
}
.kbn-page-account-box-card{
    position: relative;
    width: 100%;
    padding: .4em .8em;
    background: var(--background_tint);
    border-radius: .4em;
    display: flex;
    flex-direction: column;
    row-gap: .3em;
    .kbn-tag{
      background: var(--background);
    }
}
.kbn-page-settings{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}
.kbn-page-setting{
    position: relative;
    width: 100%;
    background: var(--background);
    border-radius: .8em;
    border: .08em solid var(--color_rgba);
    padding: 1em;
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: .8em;
    &__fonts{
        position: relative; 
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: .5em;
    }
    &__colors{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: .5em;
    }
}
.kbn-page-setting-color{
    position: relative;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    padding: .4em;
    &.active{
        border: .2em solid var(--primary);
    }
    &__container{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        transform: rotate(30deg);
        &.is--default{
            background: $default_primary;
            &::after{
                @include pseudo-el;
                width: 50%;
                height: 100%;
                top: 0;
                right: 0;
                background: $default_secondary;
            }
        }
        &.is--palette2{
            background: $palette2_primary;
            &::after{
                @include pseudo-el;
                width: 50%;
                height: 100%;
                top: 0;
                right: 0;
                background: $palette2_secondary;
            }
        }
        &.is--palette3{

            background: $palette3_primary;
            &::after{
                @include pseudo-el;
                width: 50%;
                height: 100%;
                top: 0;
                right: 0;
                background: $palette3_secondary;
            }
        }
        &.is--palette4{

            background: $palette4_primary;
            &::after{
                @include pseudo-el;
                width: 50%;
                height: 100%;
                top: 0;
                right: 0;
                background: $palette4_secondary;
            }
        }
        &.is--palette5{

            background: $palette5_primary;
            &::after{
                @include pseudo-el;
                width: 50%;
                height: 100%;
                top: 0;
                right: 0;
                background: $palette5_secondary;
            }
        }
    }
}
.kbn-page-setting-size{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: .5em;
    &__btn{
        width: 3em;
        height: 3em;
        border-radius: 50%;
        @extend %flex-center;
        background: var(--background_tint);
        i{
          font-size: 1em;
        }
      }
      &__slide{
        position: relative;
        width: calc(100% - 4em);
        input{
          width: 100%;
        }
      }
}

.kbn-page-setting-theme{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: .4em;
    flex-wrap: wrap;
  }
  .kbn-page-setting-theme-switch{
    position: relative;
    width: 5.6em;
    height: 3em;
    background: var(--background_tint);
    padding: .2em;
    border-radius: 3em;
    border: .1em solid var(--color_rgba);
    &__content{
      position: relative;
      height: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
      background: var(--background);
      @extend %flex-center;
      font-size: 1em;
      transition: .3s ease;
      border: .1em solid var(--color_rgba);
      &.is--active{
        transform: translateX(100%);
        background: var(--secondary);
      }
    }
  }
  .kbn-page-setting-theme-default{
    position: relative;
    text-decoration: underline;
    span{
      opacity: .8;
    }
    &:hover{
      span{
        opacity: 1;
        font-weight: bold;
      }
    }
  }
@keyframes animLoader {
    from{
        opacity: 0.2;
        transform: scale(.9);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}

.kbn-page-parametre{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: .5em;
    &__side{
        position: relative;
        width: 100%;
        max-width: 14em;

    }
    &__container{
        position: relative;
        width: calc(100% - 15em);
        // margin-left: auto;
        // height: 89svh;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        row-gap: .8em;
    }
}

.kbn-page-parametre-avatar{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: .8em;
    &__container{
        position: relative;
        width: 12em;
        height: 12em;
        input{
            display: none;
        }
    }
    &__actions{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .4em;
        button, label{
            width: 100%;
            @extend %flex-center;
            height: 2.5em;
        }
    }
}
.kbn-page-parametre-avatar-image{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    @extend %flex-center;
    border-radius: 50%;
    cursor: pointer;
    i{
        font-size: 3em;
    }
    img{
        @extend %image-center;
    }
    background: var(--background_tint);

}

.kbn-page-parametre-box{
    position: relative;
    width: 100%;
    background: var(--background_tint);
    border-radius: .8em;
    border: .08em solid var(--color_rgba);
    padding: 1em;
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1em;
    &__form{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .8em;
        // min-height: 30em;
    }
    &__fields{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: .5em;
    }
    &__field{
        position: relative;
        width: calc(50% - 1em);
    }
}

.kbn-page-actions{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .8em;
    &__main{
        position: relative;
        display: flex;
        // flex-wrap: wrap;
        gap: .8em;
    }
    &__side{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: .8em;
        align-items: center;
    }
}
.kbn-page-numbers{
    position: relative;
    display: flex;
    gap: .5em;
    align-items: center;
    input{
        max-width: 4em;
        height: 2.75em;
        text-align: center;
        outline: none;
        color: inherit;
        border: none;
        border-bottom: .1em solid var(--color_tint);
     
        background: transparent;
    }
}
.kbn-page-btns{
    position: relative;
    display: flex;
    align-items: center;
    min-width: 6em;
    height: 2.75em;
    border-radius: .4em;
    background: var(--background_tint);
    padding: .2em;
    button{
        position: relative;
        width: 2.5em;
        height: 100%;
        @extend %flex-center;
        border-radius: .4em;
        transition: .3s ease;
        font-size: 1.2;
        &.is--active{
            background: var(--primary);
            color: var(--button_text_color);
        }
    }
}
.kbn-page-searchItem{
    position: relative;
    width: 15em;
    select, input{
        position: relative;
        width: 100%;
        display: flex;
        column-gap: 0.2em;
        align-items: center;
        padding: 0.25em .8em;
        min-height: 2.75em;
        transition: 0.3s ease;
        border-radius: .4em;
        background: var(--background_tint);
        font-size: .98em;
    }
}
.kbn-page-search{
    position: relative;
    width: 35em;
    display: flex;
    column-gap: 0.2em;
    align-items: center;
    padding: 0.25em .8em;
    min-height: 2.75em;
    transition: 0.3s ease;
    border-radius: .4em;
    background: var(--background_tint);
    font-size: .98em;
    &.is--min{
        width: 18em;
    }
    i{
      font-size: 1.1em;
      color: currentColor;
    }
    input{
        position: relative;
        width: 100%;
        background: none;
        color: inherit;
        font-size: 1em;
        outline: none;
        border: none;
        height: 100%;
    }

}

.kbn-loader2{
    position: relative;
    width: 100%;
    height: 74svh;
    top: 0;
    left: 0;
    z-index: 999;
    @extend %flex-center;
    font-size: 2em;
    
}
.kbn-empty-status{
    position: relative;
    width: 100%;
    height: 74svh;
    top: 0;
    left: 0;
    z-index: 999;
    @extend %flex-center;
    flex-direction: column;
    row-gap: 1em;
    text-align: center;
    &__illu{
        svg{
            width: auto;
            height: 40svh;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        max-width: 30em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

.kbn-page-grid{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 1em;
}

.kbn-page-grid-item{
    position: relative;
    width: 100%;
    max-width: 28em;
}
.kbn-page-show{
    position: relative;
    width: 100%;
    display: flex;
    column-gap: 1em;
    
    &__side{
        position: relative;
        width: 100%;
        max-width: 17em;

    }
    &__container{
        position: relative;
        width: calc(100% - 18em);
        // margin-left: auto;
        // height: 89svh;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        row-gap: .8em;
    }
    &__grid{
        position: relative;
        width: 100%;
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
    }
}
.kbn-page-show-avatar{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: .8em;
    input{
        display: none;
    }
    
    &__container{
        position: relative;
        width: 12em;
        height: 12em;
        // background: var(--background_tint);
    }
    &__actions{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .4em;
        button, label{
            width: 100%;
            @extend %flex-center;
            height: 2.5em;
        }
    }
}

.kbn-page-show-avatar-image{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    @extend %flex-center;
    border-radius: 50%;
    cursor: pointer;
    i{
        font-size: 3em;
    }
    img{
        @extend %image-center;
    }
    background: var(--background_tint);

}


.kbn-page-show-box{
    position: relative;
    width: 100%;
    max-width: 38em;
    background: var(--background);
    border-radius: .8em;
    border: .08em solid var(--color_rgba);
    padding: 1em;
    height: 24em;
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: .8em;
    &__statut{
      position: absolute;
      top: 1em;
      right: 0.8em;
    }
    .kbn-page-show-box-ic{
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        opacity: .8;
    }
    h2{
        font-size: 1.2em;
    }
    &__head{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
            padding: .5em .8em;
            border-radius: .4em;
            font-size: .98em;
            border: .02em solid var(--button);
            color: var(--button);
            background: transparent;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .4em;
        padding-bottom: .8em;
    }
    &__item{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: .3em;
        flex-wrap: wrap;
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .2em;
        min-height: 18em;
        padding-bottom: .8em;
    }
    &__desc{
      position: relative;
      width: 100%;
      height: 18em;
      padding-bottom: .8em;
      overflow-y: auto;
    }
    &__loader{
        position: relative;
        width: 100%;
        height: 100%;
        @extend %flex-center;
        font-size: 2em;
    }
    &__empty{
        position: relative;
        width: 100%;
        height: 13.2em;
        @extend %flex-center;
        flex-direction: column;
        row-gap: .2em;
        font-weight: bold;
        text-align: center;
        i{
            opacity: .9;
            font-size: 1.4em;
        }
    }
}

.kbn-page-show-box-user{
    position: relative;
    width: 100%;
    padding-left: .8em;
    display: flex;
    gap: .5em;
    align-items: center;
    // flex-wrap: wrap;
    border-left: .2em solid var(--color_rgba);
    &__avatar{
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        background: var(--background_tint);
        @extend %flex-center;
        font-size: 1.6em;
        img{
            @extend %image-center;
        }
    }
    &__container{
        position: relative;
        width: calc(100% - 4.5em);
        display: flex;
        flex-direction: column;
        span{
            font-size: .9em;
        }
    }
}

.kbn-calendar{
    position: relative;
    width: 100%;
    height: 74svh;
    margin-top: .5em;
}

.kbn-page-choices{
    position: relative;
    width: 100%;
    display: flex;
    gap: 1em;
  }
  
  .kbn-page-choice{
    position: relative;
    width: 100%;
    padding: 1em;
    background: var(--background_tint);
    border-radius: .4em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 1em;
    min-height: 17em;
    font-size: .9em;
    transition: .3s ease;
    &:hover{
      background: var(--primary_rgba);
      i{
        color: var(--primary);
      }
    }
    i{
      font-size: 2.5em;
    }
    h2{
      font-size: 1.3em;
    }
    &__text{
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: .5em;
    }
  }


  .kbn-page-contain{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
    &__header{
      button{
        padding: .4em .8em;
        font-size: 1em;
        background: var(--background_tint);
        border-radius: .4em;
        display: flex;
        align-items: center;
      }
    }
  }
  .kbn-page-form{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
    &__container{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .5em;
    }
    &__action{
      position: relative;
      width: 100%;
      button{
        width: 100%;
        @extend %flex-center;
        text-align: center;
      }
    }
    &__contain{
      position: relative;
      padding: 1em;
      width: 100%;
      background: var(--background_tint);
      border-radius: .8em;
    }
    &__fields{
      position: relative;
      width: 100%;
      display: flex;
      gap: .5em;
      flex-wrap: wrap;
    }
    &__field{
      position: relative;
      width: calc(50% - 0.8em);
    }
  }
  
  .kbn-page-create-list{
    position: relative;
    width: 100%;
    &__header{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .8em;
      margin-bottom: 1em;
      h2{
        font-size: 1.2em;
      }
    }
    &__search{
      position: relative;
      width: 100%;
      max-width: 40em;
      background: var(--background_tint);
      padding: .4em .8em;
      border-radius: .4em;
      display: flex;
      align-items: center;
      gap: .4em;
      min-height: 3em;
      input{
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        color: inherit;
      }
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        gap: .8em;
        flex-wrap: wrap;
    }
    &__item{
        position: relative;
        width: 100%;
        max-width: 33em;
    }
  }

.kbn-page-creating{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__side{
        position: relative;
        width: 30em;
        display: flex;
        flex-direction: column;
        row-gap: .8em;
        max-height: 80svh;
        overflow-y: auto;
    }
    &__full{
        position: relative;
        width: calc(100% - 31em);
        max-height: 80svh;
        overflow-y: auto;
    }
}

.kbn-page-creating-patient{
    position: relative;
    padding: .5em;
    width: 100%;
    display: flex;
    // align-items: center;
    background: var(--background_tint);
    border-radius: .8em;
    gap: .5em;
    &__avatar{
        width: 4em;
        height: 4em;
        background: var(--background);
        border-radius: .4em;
        @extend %flex-center;
        overflow: hidden;
        i{
            font-size: 1.4em;
        }
        img{
            @extend %image-center;
        }
    }
    &__container{
        position: relative;
        width: calc(100% - 4.5em);
        p{
            font-size: .9em;
        }
    }
}
.kbn-page-creating-choice{
    position: relative;
    width: 100%;
    padding: 1em;
    border: .1em solid var(--color_rgba);
    display: flex;
    flex-direction: column;
    row-gap: .8em;
    border-radius: .8em;
    &__search{
        position: relative;
        width: 100%;
        display: flex;
        column-gap: 0.2em;
        align-items: center;
        padding: 0.25em .8em;
        min-height: 2.75em;
        transition: 0.3s ease;
        border-radius: .4em;
        background: var(--background_tint);
        font-size: .98em;
       
        i{
            font-size: 1.1em;
            color: currentColor;
        }
        input{
            position: relative;
            width: 100%;
            background: none;
            color: inherit;
            font-size: 1em;
            outline: none;
            border: none;
            height: 100%;
        }
    }
    &__grid{
        display: flex;
        gap: .5em;
    }
    &__item{
        position: relative;
        padding: .5em;
        border-radius: .4em;
        display: flex;
        flex-direction: column;
        border-radius: .4em;
        background: var(--background_tint);
        .kbn-tag{
            background: var(--background);
        }
        &.is--active{
            background: var(--primary_rgba);
        }
        &.is--invisble{
            display: none;
        }
    }
    &__item2{
        position: relative;
        width: 100%;
        padding: .5em;
        border-radius: .4em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: .4em;
        background: var(--background_tint);
        .kbn-tag{
            background: var(--background);
        }
        &.is--active{
            background: var(--primary_rgba);
        }
    }
}

.kbn-page-creating-form{
    position: relative;
    width: 100%;
    padding: 1em;
    border: .1em solid var(--color_rgba);
    display: flex;
    flex-direction: column;
    row-gap: .8em;
    border-radius: .8em;
    &__header{
        position: relative;
        width: 100%;
        p{
            font-size: .8em;
            opacity: .8;
        }
    }

}
.kbn-page-creating-medecin{
    position: relative;
  width: 100%;
  background: var(--background_tint);
  padding: .5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: .4em;
  border-radius: .8em;
  
  &__actions{
    position: relative;
    width: 2.5em;
    button{
      position: relative;
      width: 2.5em;
      height: 2.5em;
      border-radius: .4em;
      background: rgba($color: $danger, $alpha: .2);
      @extend %flex-center
    }
  }
  &__container{
    position: relative;
    width: calc(100% - 3em);
    display: flex;
    align-items: center;
    column-gap: .5em;
  }
  &__avatar{
    position: relative;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    @extend %flex-center;
    overflow: hidden;
    background: var(--background);
    i{
      font-size: 1.2em
    }
    img{
      @extend %image-center;
    }
  }
  &__info{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    span{
      font-size: .9em;
    }
  }
}
.kbn-page-creating-list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1em;

  &__header{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: .5em;
    align-items: center;
    flex-wrap: wrap;
    h2{
      font-size: 1.4em;
    }
  }
  &__actions{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: .5em;
    flex-wrap: wrap;
  }
  &__search{
    position: relative;
    width: 100%;
    max-width: 30em;
    padding: 0.25em .8em;
    min-height: 2.75em;
    background: var(--background_tint);
    border-radius: .4em;
    display: flex;
    align-items: center;
    column-gap: .2em;
    input{
      border: none;
      background: none;
      width: 100%;
      outline: none;
    }
  }
  &__select{
    position: relative;
    width: 100%;
    max-width: 20em;
    padding: 0.25em .8em;
    min-height: 2.75em;
    background: var(--background_tint);
    border-radius: .4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: .2em;
    select{
      border: none;
      background: none;
      outline: none;
      text-align: right;
      color: inherit;
    }
  }
  &__listing{
    position: relative;
    width: 100%;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
  }
}

.kbn-page-creating-list-item{
    position: relative;
    width: 23em;
    padding: 1em;
    background: var(--background_tint);
    border-radius: .8em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    &.is--select{
      background: var(--primary_rgba);
      .kbn-page-creating-list-item__avatar{
        background: var(--primary);
      }
    }
    &__avatar{
      position: relative;
      width: 4em;
      height: 4em;
      border-radius: 50%;
      @extend %flex-center;
      overflow: hidden;
      background: var(--background);
      i{
        font-size: 1.3em
      }
      img{
        @extend %image-center;
      }
    }
    &__info{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      span{
        font-size: .9em;
      }
    }
  }
  

.kbn-page-creating-payment{
    position: relative;
    width: 100%;
    display: flex;
    gap: 1em;
   
  }
  .kbn-page-creating-payment-form,
  .kbn-page-creating-payment-information{
    position: relative;
    width: 100%;
    padding: 1em;
    background: var(--background_tint);
    border-radius: .8em;
   
  }
  .kbn-page-creating-payment-form{
    &__container{
      position: relative;
      width: 100%;
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      row-gap: .5em;
    }
    &__fields{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .5em;
    }
    &__field{
      position: relative;
      width: 100%;
    }
    &__action{
      position: relative;
      width: 100%;
      button{
        width: 100%;
        @extend %flex-center
      }
    }
  }
  .kbn-page-creating-payment-information-rdv{
    position: relative;
    width: 100%;
    margin: .8em 0;
    &__container{
      position: relative;
      width: 100%;
    }
    &__item{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: .5em;
      align-items: center;
      padding: .2em 0;
    }
  }
  .kbn-page-creating-payment-information-patient{
    position: relative;
    width: 100%;
    margin: .5em 0;
    &__container{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: .5em;
    }
    &__avatar{
      position: relative;
      width: 3.5em;
      height: 3.5em;
      background: var(--background);
      border-radius: var(--border_ultra_xl);
      @extend %flex-center;
      img{
        @extend %image-center;
      }
    }
    &__info{
      position: relative;
      width: calc(100% - 4em);
      display: flex;
      flex-direction: column;
      row-gap: .05em;
      span{
        font-size: .8em;
      }
    }
  }
  


.kbn-page-creating-payment-form-summary{
    position: relative;
    width: 100%;
    padding: 1em;
    background: var(--background);
    border-radius: .4em;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
    margin-top: .8em;
    &__container{
      position: relative;
      width: 100%;
    }
    &__item{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: .4em;
      padding-top: .4em;
      border-top: .04em solid var(--color_tint);
      font-size: 1.1em;
    }
    &__total{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: .4em;
      padding-top: .4em;
      border-top: .04em solid var(--color_tint);
      font-size: 1.1em;
    }
  }
  

.kbn-pdf-facture{
    position: relative;
    width: 590px !important;
    height: 410px !important;
    background: #fff;
    color: #000;
    padding: 10px;
    font-size: 12px;
    &__bftlm{
      opacity: 0.5;
      img{
        width: auto;
        height: 64px;
      }
    }
    &__header{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px 10px 10px;
      align-items: center;
      border-bottom: 1px solid #cbcbcb;
    }
    &__body{
      position: relative;
      width: 100%;
    }
    &__title{
      position: relative;
      width: 100%;
      padding: 5px 10px 10px;
      border-bottom: 1px solid #cbcbcb;
      h2{
        font-size: 16px;
      }
    }
    &__patient{
      padding: 5px 10px 10px;
      border-bottom: 1px solid #cbcbcb;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }
}
.kbn-pdf-facture-patient{
h3{
    font-size: 14px;
}
&__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}
}
.kbn-pdf-facture-institue{
position: relative;
display: flex;
flex-direction: column;
row-gap: 2px;
}
.kbn-pdf-facture-description{
position: relative;
width: 100%;
display: flex;
flex-direction: column;
&__header{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
&__body{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;

}
&__auto{
    position: relative;
    width: calc(100% / 3);
    height: 30px;
    @extend %flex-vertical-center;
    padding: 0 5px;
    border: 1px solid #cbcbcb;
    font-size: 10px;
}
}

.kbn-pdf-facture-footer{
position: relative;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px;
&__signature{
    position: relative;
    width: 180px;
    height: 55px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 5px;
    background: #a9a9a9;
}
}


.kbn-page-creating-facture{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
    &__container{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1em;
    }
    &__content{
      position: relative;
      width: 100%;
      padding: 2em;
      @extend %flex-center;
      background: var(--background_tint);
    }
    &__actions{
      position: relative;
      @extend %flex-center;
      gap: .5em;
      width: 100%;
      flex-wrap: wrap;
    }
  }
  
.kbn-page-show-actions{
  position: relative;
  margin-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: .8em;
  button{
    @extend %flex-center;
  }
}

.kbn-page-show-box-user2{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  &__title{
    padding: .4em .8em;
    background-color: var(--background_tint);
    border-radius: .4em;
  }
  &__avatar{
    position: relative;
    width: 4.5em;
    height: 4.5em;
    border-radius: 50%;
    background-color: var(--background_tint);
    @extend %flex-center;
    font-size: 2em;
    overflow: hidden;
    img{
      @extend %image-center;
    }
  }
  &__info{
    position: relative;
    width: 100%;
    max-width: 18em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 8em;
    text-align: center;
  }
}
.kbn-page-account-navigation{
  position: relative;
  width: 100%;
  padding-bottom: .8em;
  border-bottom: .1em solid var(--color_rgba);
  display: flex;
  overflow-x: auto;
  gap: .8em;
  &__item{
      position: relative;
      padding: 0 .5em;
      white-space: nowrap;
      font-size: 1.2em;
      &.is--active{
          font-weight: bold;
          color: var(--primary);
          &::after{
              @include pseudo-el;
              width: 100%;
              height: 0.2em;
              background: var(--primary);
              bottom: -.6em;
              left: 0;
          }
      }
  }
  &__container{
    position: relative;
    width: 100%;
    display: none;
    padding: 1em 0;
    &.is--active{
      display: block;
    }
  }
}

.kbn-notification-items{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 60em;
  row-gap: 1em;
}

.kbn-notification-item{
  position: relative;
  width: 100%;
  background-color: vaar(--background);
  border-radius: .8em;
  border: .08em solid var(--color_rgba);
  padding: 1em;
  min-height: 9em;
  box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
  min-height: 4em;
  display: flex;
  flex-direction: column;
  row-gap: .5em;
  &.is--new{
    background: var(--background_tint);
  }
  &__content{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__actions{
    margin-top: .5em;
    display: flex;
    gap: .1em;
    flex-wrap: wrap;
  }
  .timeup{
    margin-top: .8em;
    display: block;
    font-size: .9em;
    font-style: italic;
  }
}


.kbn-product-banner{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  &__side{
    position: relative;
    width: 100%;
    max-width: 27em;
    height: 45em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  &__ads{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: .8em;
    background: var(--background);
    overflow: hidden;
    display: flex;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    span{
        position: absolute;
        bottom: 1em;
        left: 1em;
        background: var(--background);
        color: var(--color);
        padding: .5em 1em;
        border-radius: 1em;
        font-size: .8em;
    }
  }
}
.kbn-product-banner-info{
  border-radius: 1.2em;
  background: var(--background_tint);
  border: .02em solid var(--color_rgba);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 1em;
  &__details{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .7em;
    h2{
      font-size: 2.1em;
      font-family: var(--font_title);
    }
    a{
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
      &:hover{
        color: var(--primary);
      }
    }
  }
  &__orders{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .7em;
  }
  &__price{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2em;
    strong{
        font-family: var(--font_title);
        font-size: 2.4em;
    }
    span{
        font-size: .8em;
        opacity: .9;
    }
  }
  &__qte{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    button{
      position: relative;
      width: 2.5em;
      height: 2.5em;
      background: var(--background_tint);
      border-radius: 50%;
      border: .04em solid var(--color_rgba);
      @extend %flex-center;
      transition: .4s ease;
      &.is--disable{
        pointer-events: none;
        opacity: .4;
      }
    }
    input{
      max-width: 4em;
      text-align: center;
      color: inherit;
      background: none;
      outline: none;
      border: none;
    }
  }
  &__actions{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
    margin-top: 2em;
    button{
      justify-content: center;
      width: 100%;
      min-height: 3em;
    }
  }
  &__share{
    position: absolute;
    top: .5em;
    right: .5em;
    opacity: .8;
    &:hover{
      opacity: 1;
    }
    font-size: 1.2em;
  }
}
.kbn-product-banner-info-avis{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  span{
    text-decoration: underline;
    opacity: .7;
  }
  &__stars{
    position: relative;
    display: flex;
    align-items: center;
    gap: .5em;
  }
  &__star{
    position: relative;
    width: 1.2em;
    height: 1.2em;
    background: var(--color);
    clip-path: polygon(50% 0%, 63% 31%, 98% 35%, 73% 58%, 79% 91%, 50% 74%, 21% 91%, 27% 57%, 2% 35%, 36% 32%);
    opacity: .5;
    &.is--active{
      opacity: 1;
    }
  }
}
.kbn-product-banner-images{
  position: relative;
  width: 100%;
  max-width: 80em;
  height: 48em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.kbn-product-banner-image-main{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background_tint);
  border-radius: 1.8em;
  video,img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.kbn-product-banner-image-list{
  position: relative;
  width: 100%;
  max-width: 35em;
  margin: auto;
}
.kbn-product-banner-image-lil{
  position: relative;
  padding-right: .5em;
  button{
    position: relative;
    overflow: hidden;
    width: 6.5em;
    height: 6.5em;
    padding: .4em;
    border-radius: .8em;
    border: .2em solid transparent;
    background: var(--background_tint);
    &.is--active{
      border-color: var(--color);
    }
    img{
      @extend %image-center;
      border-radius: .4em;
    }
  }
}

.kbn-product-navigation{
  position: relative;
  width: 100%;
  padding: .8em;
  border-radius: .8em;
  background: var(--background);
  margin: 1em 0;

  &__container{
    position: relative;
    width: 100%;
    display: none;
    padding: 1em 0;
    // min-height: 40em;
    max-height: 80svh;
    overflow-y: auto;
    &.is--active{
      display: block;
    }
  }
}
.kbn-product-navigation-nav{
  position: relative;
  width: 100%;
  padding-bottom: .8em;
  border-bottom: .1em solid var(--color_rgba);
  display: flex;
  overflow-x: auto;
  gap: .8em;
  &__item{
      position: relative;
      padding: 0 .5em;
      white-space: nowrap;
      font-size: 1em;
      &.is--active{
          font-weight: bold;
          color: var(--primary);
          &::after{
              @include pseudo-el;
              width: 100%;
              height: 0.2em;
              background: var(--primary);
              bottom: -.8em;
              left: 0;
          }
      }
  }
}

.kbn-arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3em;
  height: 3em;
  background: var(--color);
  color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }
  &#next{
      right: .5em;
  }
  &#prev{
      left: .5em;
  }
}

.kbn-page-action-select{
  position: relative;
    width: 15em;
    select, input{
        position: relative;
        width: 100%;
        display: flex;
        column-gap: 0.2em;
        align-items: center;
        padding: 0.25em .8em;
        min-height: 2.75em;
        transition: 0.3s ease;
        border: none;
        border-radius: .4em;
        background: var(--background_tint);
        font-size: .98em;
        color: inherit;
    }
}