.kbn-l-auth-content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 45em;
    height: 100svh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5svh;
}
.kbn-l-auth-header{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2svh;
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        text-align: center;
        h1{
            font-size: 3em;
        }
        span{
            color: var(--color_tint);
            font-size: .9em;
        }
    }

}
.kbn-l-auth-partners{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: .5em;
}
.kbn-l-auth-partner{
    height: 5em;
    width: auto;
    img{
        @extend %image-width-auto;
    }
}
.kbn-l-auth-header-back{
    position: relative;
    button{
        position: relative;
        padding: .4em .8em;
        border-radius: .4em;
        background: var(--color_rgba);
        display: flex;
        align-items: center;
        gap: .2em;
        span{
            font-size: .9em;
            text-decoration: underline;
            font-weight: 500;
        }
    }
}
.kbn-l-auth-logo{
    position: relative;
    width: 100%;
    text-align: center;
    svg{
        height: 3em;
        width: auto;
        margin: auto;
        opacity: .5;
    }
}
.kbn-l-auth-container{
    position: relative;
    width: 100%;
}
.kbn-l-auth-footer{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    i{
        color: $danger;
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
    }
    &__logo{
        position: relative;
        height: 4em;
        width: auto;
        img{
            @extend %image-width-auto;
        }
    }
}
.kbn-l-auth-image{
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 45em);
    height: 100svh;
    overflow: hidden;
    @extend %flex-center;
    img{
        pointer-events: none;
        @extend %image-center;
    }
}

.kbn-auth-form{
    position: relative;
    width: 100%;
    max-width: 29em;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 2svh;
    &__fields{
        position: relative;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: .3em;
    }
    &__field{
        position: relative;
        width: 100%;
    }
    &__action{
        position: relative;
        width: 100%;
        button{
            width: 100%;
            @extend %flex-center;
        }
    }
    &__info{
        position: relative;
        width: 100%;
        text-align: center;
    }
}