@import "../../../../node_modules/include-media/dist/include-media";

@import "icon/css/animation";
@import "icon/css/kbn-codes.css";
@import "icon/css/kbn-embedded.css";
@import "icon/css/kbn-ie7-codes.css";
@import "icon/css/kbn-ie7.css";
@import "icon/css/kbn.css";

@import "./fonts/font";

@import "./variable/color";
@import "./variable/font";

@import "./init/mixins";
@import "./init/placeholder";
@import "./init/global";

@import "./components/alert";
@import "./components/button";
@import "./components/card";
@import "./components/field";
@import "./components/form";
@import "./components/modal";
@import "./components/table";

@import "./layout/auth";
@import "./layout/menu";
@import "./layout/page";
@import "./layout/setting";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

.image-gallery-slide{
    img{
         position: relative;
         width: 100%;
         height: calc(45em - 70px);
         object-fit: contain;
    }
     video{
        position: relative;
        width: 100%;
        height: calc(45em - 70px);
        object-fit: contain;
    }
}
.image-gallery-thumbnail-image{
    position: relative;
    width: 70px;
    height: 60px;
    object-fit: contain;
}
.image-gallery-content{
    &.fullscreen{
        .image-gallery-slide{
            img{
                position: relative;
                width: 100%;
                height: calc(100svh - 70px);
                object-fit: contain;
            }
             video{
                position: relative;
                width: 100%;
                height: calc(100svh - 70px);
                object-fit: contain;
            }
        }
    }
}